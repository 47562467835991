import React from "react";
import { Helmet } from "react-helmet";

import { Container, Wrapper, Box } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";

import SuccessIcon from "../images/graphics/icons/Icon_AnimatedTick.svg";

const CallBooked = (props) => {
  return (
    <Layout>
      <Seo
        title="Call Booked | FlexiTime Sales"
        pathname={props.location.pathname}
      />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Container>
        <Wrapper stackGap={70} maxWidth={800}>
          <Box stackGap={50} className="-textCenter">
            <Box stackGap={20}>
              <img
                className="-center"
                src={SuccessIcon}
                alt="Call Booked | FlexiTime Sales"
                height="100"
              />
              <Box>
                <h1>Sales Call Booked</h1>
                <h4>
                  Your call has been successfully booked with one of the team.
                  All the details about the call should be on their way to your
                  inbox now. Talk soon!
                </h4>
              </Box>
            </Box>
            <hr />
            <p>
              In the meantime, we recommend checking out a{" "}
              <a
                href="https://www.payhero.co.nz/interactive-demo"
                target="_blank"
                rel="noreferrer"
              >
                PayHero Demo
              </a>{" "}
              or{" "}
              <a
                href="https://www.droppah.com/interactive-demo"
                target="_blank"
                rel="noreferrer"
              >
                Droppah Demo
              </a>{" "}
              to get a feel for our products before we have a chat. If you have
              any further questions, please get in touch at{" "}
              <a href="mailto:support@flexitime.works">
                support@flexitime.works
              </a>
              .
            </p>
          </Box>
        </Wrapper>
      </Container>
    </Layout>
  );
};

export default CallBooked;
